import { GatsbyBrowser } from "gatsby";
import HeaderNavigation from "../components/navigation/HeaderNavigation";
import FooterNavigation from "../components/navigation/FooterNavigation";
import React, { JSX } from "react";
import BtnScrollTop from "../components/navigation/BtnScrollTop";

const EmptyLayout = ({ children }: { children: JSX.Element }) => {
  return <>{children}</>;
};
const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
  props,
}) => {
  const Layout: any =
    // @ts-ignore
    (element.type.Layout as JSX.Element) || EmptyLayout;
  return (
    <>
      <BtnScrollTop />
      <HeaderNavigation {...props} />
      <div className={"main-container"}>
        <Layout {...props}>{element}</Layout>
        <FooterNavigation />
      </div>
    </>
  );
};

export { wrapPageElement };
