import React, { FC, PropsWithChildren } from "react";
import { HeadProps } from "gatsby";
import JsonLd from "../seo/JsonLd";

export interface IHeadDatas {
  wpPage: (Queries.WpPageSeoFieldsFragment | Queries.WpPostSeoFieldsFragment) &
    Queries.WpContentNodeFieldsFragment &
    Queries.WpFeatureImageFragment_WpPage_Fragment;
}

export interface HeadOverrideProps {
  noIndex?: boolean;
}

export type DefaultHeadProps<D = {}, C = {}> = PropsWithChildren<
  HeadProps<IHeadDatas & D, C> & HeadOverrideProps
>;

export const GATSBY_BASE_URL =
  process.env.GATSBY_BASE_URL || "https://composteursdefrance.com";

export const GATSBY_ORGANIZATION_NAME = "Agriculteurs Composteurs de France";
export const GATSBY_SITE_TITLE =
  process.env.GATSBY_SITE_TITLE || GATSBY_ORGANIZATION_NAME;

export const LOGO_IMAGE_PNG_URI =
  "/images/nav/logo-acf-agriculteurs-composteurs-de-france.png";
export const LOGO_IMAGE_SVG_URI =
  "/images/nav/logo-acf-agriculteurs-composteurs-de-france.svg";

export const Head: FC<DefaultHeadProps> = (props) => {
  const { children, noIndex: noIndexOverride = false, data } = props;
  const { wpPage } = { ...data };
  const { pageMeta = "", title = "", uri = "" } = { ...wpPage };
  const {
    metaTitle = "",
    metaDescription = "",
    noindex = noIndexOverride,
  } = { ...pageMeta };
  const meta_title = metaTitle || title || "ACF";
  const base_url = GATSBY_BASE_URL;
  const canonical = `${base_url}${uri}`;
  const ogImage = wpPage?.featuredImage?.node?.og?.images?.fallback?.src || "";
  const ogImageUrl = ogImage ? base_url + ogImage.replace(/\?.*$/, "") : "";

  return (
    <>
      <html lang={"fr"} />
      <title>{meta_title}</title>
      {metaDescription && <meta name="description" content={metaDescription} />}
      {noindex && <meta name="robots" content="noindex" />}
      <link rel={"canonical"} href={canonical} />
      <meta name={"og:title"} content={meta_title} />
      {ogImage && <meta name={"og:image"} content={ogImageUrl} />}
      {ogImage && <meta name={"og:image:width"} content={"1200"} />}
      {ogImage && <meta name={"og:image:height"} content={"630"} />}
      {ogImage && <meta name={"og:image:type"} content={"image/jpeg"} />}
      {metaDescription && (
        <meta name={"og:description"} content={metaDescription} />
      )}
      {children}
    </>
  );
};

export { Head as DefaultHead };
