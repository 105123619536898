import React, { memo, useEffect } from "react";
import {
  graphql,
  Link,
  useStaticQuery,
  WrapPageElementBrowserArgs,
} from "gatsby";
import { LOGO_IMAGE_PNG_URI, LOGO_IMAGE_SVG_URI } from "./Head";

type MenuItem = NonNullable<
  NonNullable<Queries.HeaderNavigationQuery["wpMenu"]>["menuItems"]
>["nodes"][0];

const HomeLink = ({ isHome = false }) => {
  const label = "ACF - Agriculteurs Composteurs de France";
  const Logo = (
    <picture>
      <source type={"image/svg"} src={LOGO_IMAGE_SVG_URI} />
      <source type={"image/png"} src={LOGO_IMAGE_PNG_URI} />
      <img src={LOGO_IMAGE_SVG_URI} width="auto" height="43" alt={label} />
    </picture>
  );
  const props = {
    className: "navbar-brand d-sm-block d-lg-none d-xl-block",
    children: Logo,
  };
  return isHome ? (
    <h1 {...props} aria-label={label} />
  ) : (
    <Link to={"/"} {...props} title={label} />
  );
};

const HeaderNavigation = memo(
  (props: Omit<WrapPageElementBrowserArgs<any, any>, "element">) => {
    const homePath = "/";
    const { location } = props;
    // @ts-ignore
    const pathname: string = location?.pathname || "/";
    const isHome = homePath === pathname;
    useEffect(() => {
      setNavBarShow(false);
    }, [pathname]);

    const [navBarShow, setNavBarShow] = React.useState(false);

    const { wpMenu } = useStaticQuery<Queries.HeaderNavigationQuery>(graphql`
      query HeaderNavigation {
        wpMenu(locations: { eq: GATSBY_HEADER_MENU }) {
          menuItems {
            nodes {
              id
              uri
              label
              order
              parentId
              childItems {
                nodes {
                  id
                  label
                  uri
                }
              }
            }
          }
        }
      }
    `);

    const menuItems = wpMenu?.menuItems?.nodes as MenuItem[];
    const rootMenuItems = menuItems
      .filter(Boolean)
      .filter(({ parentId }) => !parentId);

    return (
      <nav
        className={`navbar navbar-expand-lg navbar-dark fixed-top main-navbar ${navBarShow ? "navbar-background" : ""}`}
      >
        <div className="container">
          <HomeLink {...{ isHome }} />
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbar10"
            onClick={() => setNavBarShow(!navBarShow)}
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div
            className={`navbar-collapse ${navBarShow ? "show" : "hide"}`}
            id="navbar10"
            style={
              {
                "--nav-height": `${rootMenuItems.length * 48}px`,
              } as React.CSSProperties
            }
          >
            <ul className="navbar-nav nav-fill w-100">
              {rootMenuItems.map(({ id, label, childItems, uri }, key) => {
                const childMenuItems:
                  | {
                      id: string;
                      label: string;
                      uri: string;
                    }[]
                  | null = childItems?.nodes as any;
                const isDropdown = !!childMenuItems?.length;
                const liProps = {
                  className: isDropdown ? "nav-item dropdown" : "nav-item",
                  key,
                };
                const linkProps = {
                  className: isDropdown
                    ? "nav-link dropdown-toggle"
                    : "nav-link",
                  to: uri || "/",
                  ...(childMenuItems && {
                    id,
                    role: "button",
                    "data-bs-toggle": "dropdown",
                    "aria-expanded": false,
                  }),
                };
                return (
                  <li {...liProps}>
                    <Link {...linkProps}>{label}</Link>
                    {childMenuItems && (
                      <ul className="dropdown-menu" aria-labelledby={id}>
                        {childMenuItems.map(({ id, label, uri }) => {
                          return (
                            <li key={id}>
                              <Link className="dropdown-item" to={uri}>
                                {label}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </nav>
    );
  },
);

export default HeaderNavigation;
