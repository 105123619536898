import React from "react";
import { useEffect } from "react";

const BtnScrollTop = () => {
  const [show, setShow] = React.useState(false);
  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 100) {
        setShow(true);
      } else {
        setShow(false);
      }
    };
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);
  return (
    <button
      id={"btnGoTop"}
      className={`fa fa-arrow-up ${show ? "visible" : "hide"}`}
      title="Retourner haut de page"
      onClick={() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }}
    ></button>
  );
};

export default BtnScrollTop;
